// extracted by mini-css-extract-plugin
export var about = "about-module--about--XY23U";
export var aboutBan = "about-module--aboutBan--Ph3RU";
export var aboutBanButton = "about-module--aboutBanButton--BOfZy";
export var aboutBanInner = "about-module--aboutBanInner--XcGAN";
export var aboutBanPara = "about-module--aboutBanPara--tTsdv";
export var aboutDisclosureBottomInfo = "about-module--aboutDisclosureBottomInfo--qVWwr";
export var aboutDisclosureContainer = "about-module--aboutDisclosureContainer--2Kzd9";
export var aboutDisclosureItem = "about-module--aboutDisclosureItem--Nupff";
export var aboutDisclosureList = "about-module--aboutDisclosureList--9bBjk";
export var aboutDisclosureTitle = "about-module--aboutDisclosureTitle--X+9mH";
export var aboutDisclosureTopInfo = "about-module--aboutDisclosureTopInfo--FcOJV";
export var aboutInfo = "about-module--aboutInfo--Ll0v2";
export var aboutManagementContainer = "about-module--aboutManagementContainer--fr3yO";
export var aboutManagementItem = "about-module--aboutManagementItem--KVi1f";
export var aboutManagementList = "about-module--aboutManagementList--uQL1V";
export var aboutManagementName = "about-module--aboutManagementName--ajs7W";
export var aboutManagementPosition = "about-module--aboutManagementPosition--r7hOp";
export var aboutManagementTitle = "about-module--aboutManagementTitle--njFDV";
export var aboutTitle = "about-module--aboutTitle--VLrew";
export var aboutVisionInfo = "about-module--aboutVisionInfo--XOEzV";
export var aboutVisionTitle = "about-module--aboutVisionTitle--eXMVq";
export var callNumber = "about-module--callNumber--Jz29A";
export var containerAbout = "about-module--containerAbout--3NvMg";
export var mobileSeedcredLink = "about-module--mobileSeedcredLink--I6jwq";
export var numbers = "about-module--numbers--ir+b7";